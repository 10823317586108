import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image, Linking, Platform } from "react-native";
import config from '../config';
import CashIcon from './../assets/icons/cash_green.svg';
import CardIcon from './../assets/icons/card.svg';
import MasterCardIcon from './../assets/icons/mastercard.svg';
import MaestroIcon from './../assets/icons/maestro.svg';
import TwintAppIcon from './../assets/icons/twint_app.svg';
import PayrexxLogoIcon from './../assets/images/payrexx-logo.png';
import TwintLogoIcon from './../assets/images/twint-logo.png';
import VisaIcon from './../assets/icons/visa.svg';
import RefundSuccessIcon from './../assets/icons/refund_success.svg';
import RefundFailedIcon from './../assets/icons/refund_failed.svg';
import I18n from '../utils/i18n';
import CustomChip from "./CustomChip";
import { formatPriceWithCurrencyCode, isWideMode } from "../utils/common";
import moment from "moment";

let payment_type_icon;

if (Platform.OS == 'web') {
    payment_type_icon = {
        'cash' : <Image source={require('./../assets/icons/cash_green.svg')} style={{width: 30, height: 30}} />,
        'card' : <Image source={require('./../assets/icons/card.svg')} style={{width: 30, height: 30}} />,
        'twint' : <Image source={require('./../assets/images/twint-logo.png')} style={{width: 50, height: 30}} />,
        'payrexx' : <Image source={require('./../assets/images/payrexx-logo.png')} style={{height: 30, width: 30}} />,
    }
} else {
    payment_type_icon = {
        'cash' : <CashIcon width={30} height={30} />,
        'card' : <CardIcon width={30} height={30} />,
        'twint' : <Image source={TwintLogoIcon} style={{height: 30, width: 30}} />,
        'payrexx' : <Image source={PayrexxLogoIcon} style={{height: 30, width: 30}} />,
    }
}

class OrderGroupDetailsCard extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(nextProps, nextState) {
        nextState = {
            ...nextState,
            ...nextProps
        }
    }

    openGps = (lat, lng, title = null) => {
        const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
        const latLng = `${lat},${lng}`;
        const label = title ? title : latLng;
        const url = Platform.select({
            ios: `${scheme}${label}@${latLng}`,
            android: `${scheme}${latLng}(${label})`,
            web: `https://www.google.com/maps/search/?api=1&query=${latLng}`
        });    
        Linking.openURL(url);
    }

    isPaymentOptionAvailable(paymentType, paymentMethod) {
        return this.props.availablePaymentOptions.find((item) => (item.paymentType == paymentType && item.paymentMethod == paymentMethod));
    }
    
    render() {

        this.orderDetails = this.props?.orderDetails ? this.props?.orderDetails : [];
        this.cancelledOrderDetails = this.props?.cancelledOrderDetails ? this.props?.cancelledOrderDetails : [];
        this.orderDetails = this.orderDetails.concat(this.cancelledOrderDetails);

        return (
            <View
                style={{backgroundColor: 'white', padding: 10, paddingTop: 10, paddingBottom: 10, margin: 5, flex: 1, flexDirection: 'column', borderRadius: 7}}
            >

                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{justifyContent: 'center'}}>
                        <View>
                            {/* show only last 6 digits of id */}
                            <Text style={{fontWeight: '700', fontSize: 17, marginTop: 5, color: 'black'}}>
                                {this.props?.id ? I18n.t('id') + ': ' + this.props.id.slice(-6).toUpperCase() : null}
                            </Text>
                            {/* {(this.props?.beeperNumber) ? (
                                <Text style={{fontWeight: '700', fontSize: 17, color: 'black'}}>
                                    {I18n.t('beeper_number') + ': ' + this.props.beeperNumber}
                                </Text>
                            ) : null} */}
                            {((this.props?.role == config.roles.CASHIER.key || this.props?.role == config.roles.SERVER.key) && this.props?.user) ? (
                                <Text style={{color: 'black', fontSize: 23, fontWeight: '600'}}>{this.props?.user?.fullname}</Text>
                            ) : null}
                        </View>
                    </View>
                </View>

                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    {(this.props?.barName || this.props?.tableNumber) ? (
                        <View style={{justifyContent: 'center'}}>
                            {(this.props?.barName && this.props?.tableNumber) ? (
                                <Text style={{fontSize: 12, color: 'gray', fontWeight: '700'}}>
                                    {this.props?.barName + ' [ ' + I18n.t('table') + ' : ' + this.props?.tableNumber + ' ]'}
                                </Text>
                            ) : null}
                            {(this.props?.barName && !this.props?.tableNumber) ? (
                                <Text style={{fontSize: 12, color: 'gray', fontWeight: '700'}}>{this.props?.barName}</Text>
                            ) : null}
                            {(!this.props?.barName && this.props?.tableNumber) ? (
                                <Text style={{fontSize: 12, color: 'gray', fontWeight: '700'}}>{I18n.t('table') + ' : ' + this.props?.tableNumber}</Text>
                            ) : null}
                        </View>
                    ) : (<View></View>)}
                    <Text style={{fontSize: 14, marginTop: 5, marginBottom: 1, color: 'gray', fontWeight: '600', textAlign: 'right'}}>{this.props?.createdAt}</Text>
                </View>

                <View style={{flex: 1, flexDirection: 'row', marginBottom: 10, marginTop: 10, justifyContent: 'space-between'}}>
                    <View style={{justifyContent: 'center'}}>
                        {(this.props?.grandTotal || this.props?.grandTotal == 0) ? (
                            <Text style={{fontWeight: '700', fontSize: 25, color: 'black'}}>
                                {formatPriceWithCurrencyCode(this.props.grandTotal, this.props?.currencyCode)}
                            </Text>
                        ) : null}
                        {(this.props?.cancelledTotal) ? (
                            <Text style={{fontWeight: '700', fontSize: 25, color: 'red'}}>
                                {formatPriceWithCurrencyCode(this.props.cancelledTotal, this.props?.currencyCode)}
                            </Text>
                        ) : null}
                    </View>

                    {/* If orderGroup is cancelled, don't show the paymentStatus */}
                    {this.props?.status == config.orderGroupStatus?.CANCELLED.key ? (
                        <View style={{justifyContent: 'center'}}>
                            {this.props?.status == config.orderGroupStatus?.CANCELLED.key ? 
                                <CustomChip text={config.orderGroupStatus?.CANCELLED.name} backgroundColor={"red"} color={"white"} borderColor={"red"} /> 
                            : null}
                        </View>
                    ) : (
                        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'center', alignItems: 'center'}}>
                            {(this.props?.paymentType) ? (
                                <View style={{ marginRight: 10 }}>
                                    {(this.props?.paymentType != config.paymentType.ONLINE.key) ? (
                                        (this.props?.paymentType in payment_type_icon) ? payment_type_icon[this.props?.paymentType] : null
                                    ) : (
                                        (this.props?.paymentMethod in payment_type_icon) ? payment_type_icon[this.props?.paymentMethod] : null
                                    )}
                                </View>
                            ) : null}
                            <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
                                {this.props?.paymentStatus == config.orderGroupPaymentStatus?.PENDING.key ? 
                                    <CustomChip text={config.orderGroupPaymentStatus?.PENDING.name} backgroundColor={"white"} color={"orange"} borderColor={"orange"} /> 
                                : null}
                                {this.props?.paymentStatus == config.orderGroupPaymentStatus?.COMPLETED.key ? 
                                    <CustomChip text={config.orderGroupPaymentStatus?.COMPLETED.name} backgroundColor={"green"} color={"white"} borderColor={"green"} /> 
                                : null}
                                {this.props?.paymentStatus == config.orderGroupPaymentStatus?.FAILED.key ? 
                                    <CustomChip text={config.orderGroupPaymentStatus?.FAILED.name} backgroundColor={"white"} color={"red"} borderColor={"red"} /> 
                                : null}
                                {this.props?.paymentStatus == config.orderGroupPaymentStatus?.REFUNDED.key ? 
                                    <CustomChip text={config.orderGroupPaymentStatus?.REFUNDED.name} backgroundColor={"white"} color={"red"} borderColor={"red"} /> 
                                : null}
                                {this.props?.paymentStatus == config.orderGroupPaymentStatus?.WAITING.key ? 
                                    <CustomChip text={config.orderGroupPaymentStatus?.WAITING.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
                                : null}
                            </View>
                        </View>
                    )}
                </View>


                {((!this.props?.history) && (this.props?.paymentStatus == config.orderGroupPaymentStatus.PENDING.key || this.props?.paymentStatus == config.orderGroupPaymentStatus.FAILED.key || this.props?.paymentStatus == config.orderGroupPaymentStatus.WAITING.key)) ? (

                    <>

                        {((this.props?.role == config.roles.USER.key) && (this.props.status != config.orderGroupStatus?.CANCELLED.key)) ? (
                            <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                                {(this.props?.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.ONLINE.key && item.paymentMethod == config.paymentMethod.TWINT.key))) ? (
                                <View style={{flex: 1, margin: 5}}>
                                    <TouchableOpacity
                                        style={{flex: 1, backgroundColor: ((this.props?.grandTotal <= 0) ? 'gray' : 'green'), padding: 10, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
                                        onPress={() => {
                                            this.props?.onPayNow(config.paymentType.ONLINE.key, config.paymentMethod.TWINT.key)
                                        }}
                                        disabled={this.props?.grandTotal <= 0}
                                    >
                                    <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                                        {I18n.t('pay_by_twint')}
                                    </Text>
                                    </TouchableOpacity>
                                </View>
                                ) : null}
                                {(this.props?.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.ONLINE.key && item.paymentMethod == config.paymentMethod.PAYREXX.key))) ? (
                                <View style={{flex: 1, margin: 5}}>
                                    <TouchableOpacity
                                        style={{flex: 1, backgroundColor: ((this.props?.grandTotal <= 0) ? 'gray' : 'green'), padding: 10, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
                                        onPress={() => {
                                            this.props?.onPayNow(config.paymentType.ONLINE.key, config.paymentMethod.PAYREXX.key)
                                        }}
                                        disabled={this.props?.grandTotal <= 0}
                                    >
                                    <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                                        {this.isPaymentOptionAvailable(config.paymentType.ONLINE.key, config.paymentMethod.TWINT.key) ? I18n.t('pay_by_card') : I18n.t('pay')}
                                    </Text>
                                    </TouchableOpacity>
                                </View>
                                ) : null}
                            </View>
                        ) : null}

                        {((this.props?.role == config.roles.CASHIER.key || this.props?.role == config.roles.SERVER.key) && (this.props.status != config.orderGroupStatus?.CANCELLED.key)) ? (
                            <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                                {(this.props?.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.CASH.key && !item.paymentMethod))) ? (
                                <View style={{flex: 1, margin: 5}}>
                                    <TouchableOpacity
                                        style={{flex: 1, backgroundColor: 'green', padding: 10, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
                                        onPress={() => {
                                            this.props?.onPayNow(config.paymentType.CASH.key)
                                        }}
                                    >
                                    <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                                        {I18n.t('cash')}
                                    </Text>
                                    </TouchableOpacity>
                                </View>
                                ) : null}
                                {(this.props?.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.CARD.key && !item.paymentMethod))) ? (
                                <View style={{flex: 1, margin: 5}}>
                                    <TouchableOpacity
                                        style={{flex: 1, backgroundColor: ((this.props?.grandTotal <= 0) ? 'gray' : 'green'), padding: 10, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
                                        onPress={() => {
                                            this.props?.onPayNow(config.paymentType.CARD.key)
                                        }}
                                        disabled={this.props?.grandTotal <= 0}
                                    >
                                    <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                                        {I18n.t('card')}
                                    </Text>
                                    </TouchableOpacity>
                                </View>
                                ) : null}
                                {(this.props?.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.TWINT.key && !item.paymentMethod))) ? (
                                <View style={{flex: 1, margin: 5}}>
                                    <TouchableOpacity
                                        style={{flex: 1, backgroundColor: ((this.props?.grandTotal <= 0) ? 'gray' : 'green'), padding: 10, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
                                        onPress={() => {
                                            this.props?.onPayNow(config.paymentType.TWINT.key, null)
                                        }}
                                        disabled={this.props?.grandTotal <= 0}
                                    >
                                    <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                                        {I18n.t('twint')}
                                    </Text>
                                    </TouchableOpacity>
                                </View>
                                ) : null}
                                {(this.props?.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.PAYREXX.key && !item.paymentMethod))) ? (
                                <View style={{flex: 1, margin: 5}}>
                                    <TouchableOpacity
                                        style={{flex: 1, backgroundColor: ((this.props?.grandTotal <= 0) ? 'gray' : 'green'), padding: 10, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
                                        onPress={() => {
                                            this.props?.onPayNow(config.paymentType.PAYREXX.key, null)
                                        }}
                                        disabled={this.props?.grandTotal <= 0}
                                    >
                                    <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                                        {/* {I18n.t('twint') + ' (' + I18n.t('payrexx') + ')'} */}
                                        {I18n.t('twint')}
                                    </Text>
                                    </TouchableOpacity>
                                </View>
                                ) : null}
                            </View>
                        ) : null}

                        {(this.props?.paymentType != config.paymentType.CARD.key) ? (
                            <View style={{flex: 1, flexDirection: 'row', marginBottom: 20}}>
                                {(
                                    (this.props?.role == config.roles.USER.key && (!this.props?.tableNumber) && (this.props?.paymentStatus == config.orderGroupPaymentStatus?.WAITING.key || this.props?.paymentStatus == config.orderGroupPaymentStatus?.FAILED.key) && (this.props.status != config.orderGroupStatus?.CANCELLED.key))
                                    || ((this.props?.role == config.roles.CASHIER.key || this.props?.role == config.roles.SERVER.key) && (this.props.status != config.orderGroupStatus?.CANCELLED.key))
                                ) ? (
                                    <>
                                        <TouchableOpacity
                                            style={
                                                Platform.OS == 'web' ?
                                                {flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: config.dangerColor, borderRadius: 10, padding: 20, width: '100%'} :
                                                {flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: config.dangerColor, borderRadius: 10, padding: 10, width: '100%'}
                                            }
                                            onPress={() => {
                                                this.props?.onCancelOrder()
                                            }}
                                        >
                                            <Text style={{color: 'white', fontSize: 20, fontWeight: '700'}}>
                                                {I18n.t('cancel_order')}
                                            </Text>
                                        </TouchableOpacity>
                                    </>
                                ) : null}
                            </View>
                        ) : null}

                    </>
                ) : null }


                {/* <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{flexDirection: 'row'}}>
                        {(this.props?.paymentType in payment_type_icon) ? (
                            <View style={{marginRight: 10}}>
                                {payment_type_icon[this.props?.paymentType]}
                            </View>
                        ) : null}
                        {(this.props?.paymentMethod in payment_method_icon) ? (
                            <View style={{marginRight: 10}}>
                                {payment_method_icon[this.props?.paymentMethod]}
                            </View>
                        ) : null}
                        {(this.props?.status == config.orderGroupStatus?.CANCELLED.key && this.props?.payrexxStatus == "refunded") && (
                            payrexx_status_icon['refund_success']
                        )}
                        {(this.props?.status == config.orderGroupStatus?.CANCELLED.key && this.props?.payrexxStatus != "refunded") && (
                            payrexx_status_icon['refund_failed']
                        )}
                    </View>
                    <View style={{justifyContent: 'center'}}>
                        {this.props?.status == config.orderGroupStatus?.PAYMENT_PENDING.key ? 
                            <CustomChip text={config.orderGroupStatus?.PAYMENT_PENDING.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
                        : null}
                        {this.props?.status == config.orderGroupStatus?.PAYMENT_FAILED.key ? 
                            <CustomChip text={config.orderGroupStatus?.PAYMENT_FAILED.name} backgroundColor={"white"} color={"red"} borderColor={"red"} /> 
                        : null}
                        {this.props?.status == config.orderGroupStatus?.PENDING.key ? 
                            <CustomChip text={config.orderGroupStatus?.PENDING.name} /> 
                        : null}
                        {this.props?.status == config.orderGroupStatus?.CONFIRMED.key ? 
                            <CustomChip text={config.orderGroupStatus?.CONFIRMED.name} /> 
                        : null}
                        {this.props?.status == config.orderGroupStatus?.PROCESSING.key ? 
                            <CustomChip text={config.orderGroupStatus?.PROCESSING.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
                        : null}
                        {this.props?.status == config.orderGroupStatus?.READY.key ? 
                            <CustomChip text={config.orderGroupStatus?.READY.name} backgroundColor={"white"} color={"green"} borderColor={"green"} /> 
                        : null}
                        {this.props?.status == config.orderGroupStatus?.COMPLETED.key ? 
                            <CustomChip text={config.orderGroupStatus?.COMPLETED.name} backgroundColor={"green"} color={"white"} borderColor={"green"} /> 
                        : null}
                        {this.props?.status == config.orderGroupStatus?.CANCELLED.key ? 
                            <CustomChip text={config.orderGroupStatus?.CANCELLED.name} backgroundColor={"red"} color={"white"} borderColor={"red"} /> 
                        : null}
                    </View>
                </View> */}
                
                {this.orderDetails?.map((orderDetail, index) => {

                    return (
                        <View key={index}>

                            <View style={[{ height: 1, overflow: 'hidden', marginTop: 10, marginBottom: 10 }]}>
                                <View style={[{ height: 2, borderWidth: 1, borderColor: 'darkgray', borderStyle: 'dashed' }]}></View>
                            </View>

                            <View style={{borderBottomColor: 'gray', borderBottomWidth: 1, marginTop: 20}}>
                                <View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row', marginBottom: 3}}>
                                    <Text style={{color: 'gray', fontWeight: '600'}}>{I18n.t('order_id') + ': ' + (orderDetail?.order?.shortOrderId).toUpperCase()}</Text>
                                    <Text style={{color: 'gray', fontWeight: '600'}}>{moment(orderDetail?.order?.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
                                </View>
                            </View>
                            <View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row', marginBottom: 5}}>
                                <View>
                                    <Text style={{fontSize: 23, fontWeight: '600', color: 'black'}}>{orderDetail?.order?.beeperNumber}</Text>
                                </View>
                                <View>
                                    <Text style={{color: 'gray', fontWeight: '600'}}>{orderDetail?.order?.groupName}</Text>
                                </View>
                            </View>

                            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                                <View style={{justifyContent: 'center'}}>
                                    {orderDetail.order.status == config.orderStatus.PAYMENT_PENDING.key ? 
                                        <CustomChip text={config.orderStatus.PAYMENT_PENDING.name} backgroundColor={"white"} color={"orange"} borderColor={"orange"} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.WAITING_FOR_PAYMENT.key ? 
                                        <CustomChip text={config.orderStatus.WAITING_FOR_PAYMENT.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.PAYMENT_FAILED.key ? 
                                        <CustomChip text={config.orderStatus.PAYMENT_FAILED.name} backgroundColor={"white"} color={"red"} borderColor={"red"} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.PENDING.key ? 
                                        <CustomChip text={config.orderStatus.PENDING.name} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.CONFIRMED.key ? 
                                        <CustomChip text={config.orderStatus.CONFIRMED.name} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.PROCESSING.key ? 
                                        <CustomChip text={config.orderStatus.PROCESSING.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.READY.key ? 
                                        <CustomChip text={config.orderStatus.READY.name} backgroundColor={"white"} color={"green"} borderColor={"green"} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.COMPLETED.key ? 
                                        <CustomChip text={config.orderStatus.COMPLETED.name} backgroundColor={"green"} color={"white"} borderColor={"green"} /> 
                                    : null}
                                    {orderDetail.order.status == config.orderStatus.CANCELLED.key ? 
                                        <CustomChip text={config.orderStatus.CANCELLED.name} backgroundColor={"red"} color={"white"} borderColor={"red"} /> 
                                    : null}
                                </View>
                                <View>
                                    <Text style={{fontWeight: '700', fontSize: 25, padding: 10, color: 'black', justifyContent: 'flex-end', borderColor: config.primaryColor, borderWidth: 2, borderRadius: 20}}>
                                        {orderDetail.order.shortOrderNumber}
                                    </Text>
                                </View>
                            </View>

                            {orderDetail?.order?.consumptions?.map((consumption, index) => {

                                return (
                                    <View key={index}>
                                        <View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row', marginTop: 3}}>
                                            <View style={{justifyContent: 'center'}}>
                                                <Text style={{fontSize: 23, fontWeight: '600', color: 'black'}}>
                                                    <Text style={{fontSize: 32, fontWeight: '600', color: 'red'}}>{consumption.quantity}</Text>
                                                    <Text>{' x ' + consumption.name}</Text>
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row', marginBottom: 3}}>
                                            <Text style={{color: 'gray', fontWeight: '600'}}>{formatPriceWithCurrencyCode(consumption?.price, this.props?.currencyCode)}</Text>
                                            <View style={{justifyContent: 'flex-end'}}>
                                                <Text style={{fontSize: 20, fontWeight: '600', color: 'black'}}>{(formatPriceWithCurrencyCode(consumption?.total, this.props?.currencyCode))}</Text>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })}

                            <View style={{flex: 1, alignItems: 'flex-end', borderTopWidth: 1}}>
                                <Text style={{fontWeight: '700', fontSize: 20, marginTop: 5, marginBottom: 5, color: 'black'}}>{formatPriceWithCurrencyCode(orderDetail.order.total, this.props?.currencyCode)}</Text>
                            </View>
                        
                        </View>
                    )
                })}

                {/* Cancel order group for cashier */}
                {/* {(this.props?.role == config.roles.CASHIER.key) ? (
                    <View>
                        {(
                            this.props?.status == config.orderGroupStatus?.PENDING.key ||
                            this.props?.status == config.orderGroupStatus?.CONFIRMED.key || 
                            this.props?.status == config.orderGroupStatus?.PROCESSING.key ||
                            this.props?.status == config.orderGroupStatus?.READY.key ||
                            this.props?.status == config.orderGroupStatus?.COMPLETED.key
                        ) ? (
                            <TouchableOpacity
                                style={{backgroundColor: config.dangerColor, padding: 10, borderRadius: 5, width: '100%'}}
                                onPress={() => this.props.onCancelOrderGroup(this.props.id)}
                            >
                                <Text style={{color: 'white', fontWeight: '700', fontSize: 15, textAlign: 'center'}}>{I18n.t('cancel_order_group')}</Text>
                            </TouchableOpacity>
                        ) : null}
                    </View>
                ) : null} */}

            </View>
        );
    }
}

const styles = StyleSheet.create({
    navigation_button_text: {
        color: 'gray'
    },
    buttonGroup: {
        height: 50,
        padding: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        // backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 1)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        borderWidth: 2,
    },
    wideTouchableButton: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        // width: 80,
        paddingLeft: 20,
        paddingRight: 20,
        height: 40
        // borderWidth: 3,
    },
    touchableButton: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40
        // borderWidth: 3,
    },
    wideButtonText: {
        fontSize: 20,
        // color: 'rgba(8, 96, 126, 1)',
        color: 'white'
    },
    buttonText: {
        fontSize: 29,
        // color: 'rgba(8, 96, 126, 1)',
        color: 'white'
    },
    count:{
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        width: 40,
        textAlign: 'center',
        color: 'black'
    },
    addButtonGroup: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        // width: 120,
        // height: 40
    },
    buttonView: {
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5
    },
    addText: {
        fontSize: 18,
        fontWeight: '500',
        color: 'white'
    }
});

export default OrderGroupDetailsCard;